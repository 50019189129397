import React, { useState } from "react";
import styles from "./CartComponet.module.css";
import { Info } from "../../Icon";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Delete } from "@mui/icons-material";
import { matchActions } from "../../actions";
import { matchStore, userStore } from "../../zustand";
import { Locks, Popup, ReadMorePopup } from "../../Component";
import vsIcon from "../../asset/vs.png";

const TEXT_LENGTH = 180;

const getTextToRender = (text) => {
  if (text.length > TEXT_LENGTH) {
    return text.slice(0, TEXT_LENGTH) + "... ";
  }

  return text;
};

const CartComponent = ({ match }) => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // User Store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  // Match Store
  const { removeMatch } = matchStore((state) => ({
    removeMatch: state.removeMatch,
  }));

  const formattedDate = moment(match.createdAt)
    .tz("America/New_York")
    .format("h:mm A [ET] | ddd MMM D");

  const handleDelete = async () => {
    // Delete Match API
    const deleteMatchRes = await matchActions.deleteMatch(match.id);

    if (deleteMatchRes.code === "OK") {
      removeMatch(deleteMatchRes?.data?.id);
      alert("Match Deleted Successfully!!");
    }
    setIsOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <p className={styles.nflText}>{match.league}:</p>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <p className={styles.timeText}>{formattedDate}</p>
          {user?.role === "ADMIN" && (
            <Delete
              onClick={() => {
                setIsOpen(true);
              }}
              sx={{ color: "red", cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      <div className={styles.matchDetails}>
        <div
          className={styles.teams}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            className={styles.teamText}
            style={{
              marginRight: 16,
            }}
          >{`${match.team1}`}</p>
          <img src={vsIcon} alt="" width={18} height={18} />
          <p
            style={{
              marginLeft: 16,
            }}
            className={styles.teamText}
          >
            {match.team2}
          </p>
        </div>
        <div className={styles.odds}>
          <div className={styles.lockIcon}>
            <Locks lockValue={match.lockValue} />
            {/* {Array.from({ length: match.lockValue }).map((item) => {
							return <Lock />;
						})} */}
          </div>
          <p className={styles.oddsText}>{match.odds}</p>
        </div>
      </div>
      <div className={styles.info}>
        <Info />
        <p className={styles.infoText}>
          {getTextToRender(match.reasoning)}
          {match.reasoning.length > TEXT_LENGTH && (
            <span
              className={styles.readMoreLink}
              onClick={() => {
                setOpen(true);
              }}
            >
              Read more
            </span>
          )}
        </p>
      </div>

      <ReadMorePopup
        open={open}
        handleClose={handleClose}
        text={match.reasoning}
        heading={match.league}
      />

      <Popup
        open={isOpen}
        handleClose={handleDeletePopup}
        title="Delete Match"
        message="Are you sure you want to delete this match?"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default CartComponent;
