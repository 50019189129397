import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import logo from "../../asset/logo.png";

import { MatchPlayerTab } from "../../Component";
import { userStore } from "../../zustand";

import styles from "./Dashboard.module.css";

const DashboardView = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");

  // User Store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  return (
    <>
      {user?.role === "ADMIN" ? (
        <div
          className={styles.wrappper}
          style={{ width: "100%", height: "100%" }}
        >
          <div style={{ color: "#fff", paddingTop: "71px" }}>
            <Box>
              <p className={styles.welcome}>Welcome to Your Dashboard</p>
            </Box>
          </div>

          <MatchPlayerTab />
        </div>
      ) : (
        <div
          className={styles.wrappper}
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
        >
          <div
            style={{ color: "#fff", paddingTop: isMobile ? "20px" : "71px" }}
          >
            <Box>
              {isMobile && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <img src={logo} alt="logo" />
                </div>
              )}

              <p className={styles.welcome}>Welcome to Your Dashboard</p>
            </Box>
          </div>

          <MatchPlayerTab />
        </div>
      )}
    </>
  );
};

export default DashboardView;
